<template>
  <v-container class="pa-0 ma-0" fluid>
    <v-img src="../assets/parking.jpg" max-height="200">
      <v-container fill-height fluid>
        <v-row justify="center" align="center" no-gutters>
          <div class="text-h1 white--text">Otomoto</div>
        </v-row>
      </v-container>
    </v-img>

    <v-container fill-height fluid v-for="(item, i) in items" :key="i">
      <v-row justify="center" align="center" no-gutters max-height="400">
        <v-col cols="6" lg="5" class="pa-8" :order="i % 2 ? 1 : 12">
          <v-img v-if="item.img != null" :src="item.img" max-height="300" />
          <iframe
            :id="item.iframePort"
            v-else
            class="prediction"
            :src="iframeUrl(item.iframePort)"
            width="100%"
            scrolling="no"
          />
        </v-col>
        <v-col cols="6" lg="5" class="pa-2" :order="i % 2 ? 12 : 1">
          <div class="text-h3 text-center mb-4">
            {{ item.header }}
          </div>
          <div v-for="(text, j) in item.texts" :key="j" class="text-h6 mb-4">
            {{ text }}
          </div>
        </v-col>
      </v-row>
      <iframe
        :id="dashes[i]"
        v-if="dashes[i]"
        class="prj"
        :src="iframeUrl(dashes[i])"
        width="100%"
        max-height="100vh"
        scrolling="no"
      />
    </v-container>
  </v-container>
</template>

<script>
export default {
  created() {
    this.origin = process.env.VUE_APP_HOST;
    window.addEventListener("message", (event) => {
      if (event.origin.startsWith("https://bczarnecki.com")) {
        const iframe = document.getElementById(event.data.id);
        iframe.style.height = `${event.data.height}px`;
      } else {
        return;
      }
    });
  },
  data() {
    return {
      origin: "",
      items: [
        {
          header: "Project",
          texts: [
            "The object of the analysis are cars offered for sale on the otomoto.pl website, it is a twin website to otodom.pl which I analyzed some time ago. I was primarily interested in the distribution of cars for sale filtered based on their brand and price.",
          ],
          img: require("../assets/otomoto_sc.jpg"),
        },
        {
          // header: "Getting data",
          texts: [
            "For this purpose, I have written a program that downloads data from the otomoto.pl website. The downloaded data has been pre-cleared and then saved in the PostgreSQL database. This solution eases the work on data as it recognizes only the selected information through SQL queries.",
            "Once we have the originally cleaned data, it is possible to proceed with their analysis. In order to accelerate the operation of data visualization, I limited their amount.",
          ],
          img: require("../assets/tesla.jpg"),
        },
        {
          header: "Visualisation",
          texts: [
            "The example visualizations that will be shown here are created using plotly or dash. The interactive visualizations allow a user to choose what is the most interesting.",
            "The first visualization consists of a map showing the location and price. Menu on the right provides an user with choosing a price range and the brands of interest.",
          ],
          img: require("../assets/car-concept.jpg"),
        },
        {
          header: "The next visualization shows two charts:",
          texts: [
            "Primo - Price distribution depending on the year of car production",
            "Secondo - Price distribution depending on the car mileage",
            "In the window and charts, one may select the brand of interest.",
          ],
          img: require("../assets/antique-car.jpg"),
        },
      ],
      dashes: [undefined, undefined, 8202, 8203],
    };
  },
  methods: {
    iframeUrl(port) {
      let outputUrl = "";
      outputUrl += this.origin;
      outputUrl += ":";
      if (process.env.VUE_APP_LOCAL) {
        outputUrl += `${port + parseInt(process.env.VUE_APP_PORT_OFFSET)}`;
      } else {
        outputUrl += port;
      }
      return outputUrl;
    },
  },
};
</script>


<style scoped>
.prj {
  border: hidden;
  height: 100%;
  width: 100%;
}

.prediction {
  border: hidden;
  overflow: hidden;
}
</style>