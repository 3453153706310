<template>
  <v-container class="pa-0 ma-0" fluid>
    <v-img src="../assets/neiborhood.jpg" max-height="200">
      <v-container fill-height fluid>
        <v-row justify="center" align="center" no-gutters>
          <div class="text-h1 white--text">Otodom</div>
        </v-row>
      </v-container>
    </v-img>

    <v-row
      justify="center"
      align="center"
      no-gutters
      max-height="400"
      v-for="(item, i) in items"
      :key="i"
    >
      <v-col cols="6" lg="5" class="pa-8" :order="i % 2 ? 1 : 12">
        <v-img v-if="item.img != null" :src="item.img" max-height="300" />
        <iframe
          v-else
          class="prediction"
          :id="item.iframePort"
          :src="iframeUrl(item.iframePort)"
          height="300px"
          width="100%"
          scrolling="no"
        />
      </v-col>
      <v-col cols="6" lg="5" class="pa-2" :order="i % 2 ? 12 : 1">
        <div class="text-h3 text-center mb-4">
          {{ item.header }}
        </div>
        <div v-for="(text, j) in item.texts" :key="j" class="text-h6 mb-4">
          {{ text }}
        </div>
      </v-col>
    </v-row>

    <iframe
      class="prj"
      id="8200"
      :src="iframeUrl(8200)"
      width="100%"
      scrolling="no"
    />
  </v-container>
</template>

<script>
export default {
  created() {
    this.origin = process.env.VUE_APP_HOST;
    window.addEventListener("message", (event) => {
      if (event.origin.startsWith("https://bczarnecki.com")) {
        const iframe = document.getElementById(event.data.id)
        iframe.style.height = `${event.data.height}px`
      } else {
        return;
      }
    });
  },
  data() {
    return {
      origin: "",
      items: [
        {
          header: "Project",
          texts: [
            "I have been interested in the Warsaw real estate market. For this reason, I decided to perform an analysis of this sector. I continued to download the data from the most popular website dealing in the sale and rental of real estate in Poland (otodom).",
          ],
          img: require("../assets/otodom_web.jpg"),
        },
        {
          header: "Getting data",
          texts: [
            "I was mainly interested in the key information that characterized a given flat, which contributed to a real impact on the price of a flat, including area, location and furniture.",
            'To retrieve information, I have written a program that opens every page with an advertisement about an apartment and downloads its content. Using the "beautifulsoup" library, I searched for information needed to conduct this analysis.',
          ],
          img: require("../assets/getting_data_web.jpg"),
        },
        {
          header: "Data cleaning",
          texts: [
            'A lot of numerical data has been added with units such as "PLN" or "m2". I removed unnecessary characters and it ameliorates an analysis of the numerical data.',
            "Since the data is entered by ordinary users, sometimes there may be data that deviates from the norm. It can be text in the field where I expect numbers or data very different from reality. For this reason, I clear the data from all incorrect data.",
          ],
          img: require("../assets/cleaning-web.jpg"),
        },
        {
          header: "Flats localization",
          texts: [
            'To achieve the location of the apartment, I download data with a given location. It contains details as: city, district, street and building number. Using the "GeoPy" library, I generated the latitude and longitude from the downloaded location, through which I could apply the data with the location of the apartments in the map.',
          ],
          img: require("../assets/map_markers_web.png"),
        },
        {
          header: "Database",
          texts: [
            'Further, the data is loaded into the "PostgreSQL" database where the retrieved information is stored. It is a convenient solution because SQL queries enable a simple selection of needed information.',
          ],
          img: require("../assets/SQL_web.png"),
        },
        {
          header: "Model Regression",
          texts: [
            "I found that many people would like to, for example, check the estimated price of an apartment that is not currently on the market. Therefore, I used regression to be able to check the hypothetical price of such an apartment. The model works well when looking for standard flats. The less real the data is, the less accurate a model becomes as it shows abstract data.",
          ],
          iframePort: 8201,
        },
        {
          header: "Visualization with Dash",
          texts: [
            'I considered many different options for visualizing the downloaded data, such as classic graphs from the "plotly" library as well as visualizations in Microsoft Power BI. However, I found that interactive visualizations using "Dash" would be most effective. They provide the user with most options, such as manual data browsing and parameter variables that allow them to select the data. Moreover, Dash offers extensive possibilities of styling the displayed data using CSS. We can create our own style as well as load an existing one.',
            "This is one of the visualizations that show us apartments for rent and for sale in the ranges chosen by a user.",
          ],
          img: require("../assets/data_dash_web.jpg"),
        },
      ],
    };
  },
  methods: {
    iframeUrl(port) {
      let outputUrl = "";
      outputUrl += this.origin;
      outputUrl += ":";
      if (process.env.VUE_APP_LOCAL) {
        outputUrl += `${port + parseInt(process.env.VUE_APP_PORT_OFFSET)}`;
      } else {
        outputUrl += port;
      }
      return outputUrl;
    },
  },
};
</script>


<style scoped>
.prj {
  height: 100vh;
  border: hidden;
}

.prediction {
  border: hidden;
  overflow: hidden;
}
</style>