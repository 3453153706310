<template>
  <v-container fluid class="ma-0 pa-0">
    <v-img src="../assets/mountain.jpg" class="landingImg">
      <v-container fill-height fluid>
        <v-row justify="center" align="center" no-gutters>
          <v-col align="right">
            <div class="text-h3 white--text font-weight-bold">
              Bartosz Czarnecki
            </div>
            <div class="text-h5 white--text font-weight-light">
              Data Science | Data Analytics
            </div>
          </v-col>
          <v-col class="ma-12">
            <v-avatar class="outlined" size="300">
              <v-img src="../assets/ava.jpg" />
            </v-avatar>
          </v-col>
        </v-row>
      </v-container>
    </v-img>

    <v-row class="ma-4 pt-16 pb-16" no-gutters justify="center">
      <v-col cols="12" md="3" v-for="(card, i) in cards" :key="i">
        <v-card height="100%" elevation="0">
          <v-row justify="center" class="ma-4">
            <v-icon x-large>{{ card.icon }}</v-icon>
          </v-row>
          <v-card-title
            style="word-break: break-word"
            class="justify-center text-center text-h4"
            >{{ card.title }}</v-card-title
          >
          <v-card-text class="text-center text-subtitle-1">
            {{ card.text }}
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <div class="text-center text-h2">MY SKILLS</div>
    <v-row class="ml-4 pb-16" align="center" justify="center">
      <v-col cols="7">
        <v-expansion-panels flat>
          <v-expansion-panel
            class="pa-0 ma-0 elevHovered"
            v-for="(bar, i) in skillBars"
            :key="i"
            :disabled="bar.disabled"
          >
            <v-hover v-slot="{ hover }">
              <v-card class="ma-2" :elevation="hover && !bar.disabled ? 6 : 0">
                <v-expansion-panel-header class="bars" expand-icon="">
                  <div class="text-h5">{{ bar.text }}</div>
                  <v-progress-linear
                    :value="bar.value"
                    :buffer-value="bar.buf"
                    :color="bar.color"
                    height="10"
                    rounded
                    class="mt-2 mb-2"
                  ></v-progress-linear>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-container class="pa-0 ma-0">
                    <v-container
                      v-for="(subBar, j) in bar.subBars"
                      :key="j"
                      class="pa-1 ma-0"
                    >
                      <v-row justify="center" align="center" no-gutters>
                        <v-col class="ml-2" justify="center">
                          <div class="text-subtitle-1">{{ subBar.text }}</div>
                          <v-divider />
                        </v-col>
                        <v-col cols="10" />
                      </v-row>
                    </v-container>
                  </v-container>
                </v-expansion-panel-content>
              </v-card>
            </v-hover>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <v-col cols="2" />
    </v-row>

    <v-parallax src="../assets/analytics.jpg" max-height="800">
      <v-container fill-height fluid>
        <v-row justify="center" align="center" no-gutters>
          <div class="text-h2 black--text">PROJECTS</div>
        </v-row>
        <v-row justify="center" align="center" no-gutters>
          <v-col v-for="(project, i) in projectsCards" :key="i" cols="5">
            <v-hover v-slot="{ hover }">
              <v-card
                :elevation="hover ? 12 : 2"
                class="ma-6"
                @click="$router.push(project.url)"
              >
                <v-img height="250" :src="project.image">
                  <v-container
                    fluid
                    fill-height
                    class="prjImg pa-0"
                    :class="{ prjImgHover: hover }"
                  >
                    <v-row justify="center" align="center">
                      <v-card-title
                        class="text-center white--text prjText text-h3 pb-0"
                      >
                        {{ project.title }}
                      </v-card-title>
                      <v-card-text class="text-center white--text prjText">
                        {{ project.text }}
                      </v-card-text>
                    </v-row>
                  </v-container>
                </v-img>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
      </v-container>
    </v-parallax>

    <v-container class="mt-12">
      <v-row justify="center" align="center" no-gutters>
        <v-col cols="9">
          <v-row justify="center" align="center" no-gutters>
            <v-col cols="8">
              <div class="text-center text-h2 black--text ma-10">ABOUT ME</div>
              <div class="text-h6">
                I’m Bartosz, an economics student interested in Data Science and
                Data analytic fields. I have always been passionate about the
                topic of information and data, but most of all, I was interested
                in what I can deduct from this and how to use it. Due to my
                curiosity about correlations in economy and business, I decided
                to pursue a degree in economics at Kozminski University. Looking
                for a tool that would allow me to comprehend more, consequently,
                provide me with drawing conclusions and analyzing information, I
                came across Data science. In order to facilitate my growth and
                become more knowledgeable in this field, I found courses on the
                Coursera platform. The topic absorbed me completely. Thus, I
                completed the IBM Data Science Certificate.
              </div>
            </v-col>
            <v-col cols="4" align="center" class="mt-12">
              <v-container>
                <v-avatar class="outlined" size="100%">
                  <v-img src="../assets/ava.jpg" />
                </v-avatar>
              </v-container>
              <v-container fluid class="mt-6">
                <v-row justify="center" align="center" no-gutters>
                  <v-col v-for="(icon, i) in icons" :key="i">
                    <v-btn
                      fab
                      width="100"
                      height="100"
                      text
                      :href="icon.url"
                      target="_blank"
                    >
                      <v-icon size="100">{{ icon.icon }}</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
          <v-row justify="center" align="center" no-gutters>
            <div class="text-center text-h4 black--text mt-12">
              SPORTS AND OTHER ACTIVITIES
            </div>
            <div class="text-h6 mt-8">
              In my free time, I like to develop myself by attending various
              student clubs. At university, I got into the Kozminski Business
              Club and SKN Data Science in Finance where I can network and
              discuss engaging topics. Moreover, we organize workshops about
              Data Science and meetings with specialists, who share their
              professional experience.
            </div>
            <div class="text-h6 mt-4">
              But I always find time for activities like gym, swimming or
              cycling. These activities, which are an integral part of my weekly
              schedule, support me maintaining a cool head and achieving better
              results.
            </div>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <div class="bottomSpacer" />
  </v-container>
</template>

<script>
export default {
  name: "Landing",

  data() {
    return {
      cards: [
        {
          title: "Collect",
          text: "I am experienced with questionnaire design and building web scraping programs, with a background in economy to collect and process data.",
          icon: "mdi-database",
        },
        {
          title: "Process",
          text: "I am using classification, regression, clustering models to process the data and extract the necessary information from it.",
          icon: "mdi-sitemap",
        },
        {
          title: "Visualize",
          text: "I have experience with using Dash and Microsoft Power BI to create interactive presentations and static infographics that help understand the data and take conclusions from them.",
          icon: "mdi-chart-scatter-plot-hexbin",
        },
      ],
      projectsCards: [
        {
          title: "Otomoto",
          text: "Otomoto data visualisation",
          image: require("../assets/mclaren.jpg"),
          url: "/portfolio/otomoto",
        },
        {
          title: "Otodom",
          text: "Otodom data visualisation",
          image: require("../assets/buildings.jpg"),
          url: "/portfolio/otodom",
        },
      ],
      icons: [
        {
          icon: "mdi-linkedin",
          url: "https://linkedin.com/in/bartosz-czarnecki-06345917b",
        },
        {
          icon: "mdi-github",
          url: "https://github.com/Bartoszcz28",
        },
      ],
      skillBars: [
        {
          text: "Python",
          color: "red",
          value: 70,
          subBars: [
            {
              text: "Pandas",
            },
            {
              text: "Numpy",
            },
            {
              text: "Dash",
            },
            {
              text: "Plotly",
            },
          ],
        },
        {
          text: "SQL",
          color: "blue",
          value: 50,
          subBars: [
            {
              text: "Postgres",
            },
            {
              text: "DB2",
            },
          ],
        },
        {
          text: "Microsoft",
          color: "yellow",
          value: 60,
          subBars: [
            {
              text: "Excel",
            },
            {
              text: "Power BI",
            },
          ],
        },
        {
          text: "Git",
          color: "green",
          value: 60,
          disabled: true,
        },
      ],
    };
  },
};
</script>

<style scoped>
.landingImg {
  height: calc(100vh - 64px);
}
.prjImg {
  transition: backdrop-filter 0.2s ease-in-out;
}
.prjImgHover {
  backdrop-filter: blur(5px) brightness(70%);
}
.bottomSpacer {
  height: 200px;
  display: block;
  width: 100%;
}
.bars {
  display: block;
}
.elevHovered {
  background-color: gray;
}
</style>